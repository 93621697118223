import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Divider,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import { fetchData } from '../lib/fetchData';

const ImportModalDetails = ({ data }) => {
  const [errorData, setErrorData] = useState(null);
  const tableRows = [];
  const importId = data.importId;

  const headerStyle = {
    fontWeight: 'bold',
    border: '1px solid black',
    padding: '8px',
  };

  const cellStyle = {
    border: '1px solid black',
    padding: '8px',
  };

  useEffect(() => {
    fetchData(`dataerrordetails/${importId}`, setErrorData);
  }, [importId]);

  if (
    (data?.status == 'Data_Error' || data?.jobStatus == 'Data_Error') &&
    errorData == null
  )
    return (
      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
        Looks like we have encounter some problem with the Import ID, please try
        again. If the problem persist, please reach out to iHub Support team.
      </Typography>
    );

  if (
    (data?.status == 'Data_Error' || data?.jobStatus == 'Data_Error') &&
    errorData.errorDetails != null
  ) {
    errorData.errorDetails.map((error, index) =>
      tableRows.push(
        <TableRow key={index}>
          <TableCell style={cellStyle}>{index + 1}</TableCell>
          <TableCell style={cellStyle}>{error.errorMessage}</TableCell>
        </TableRow>,
      ),
    );
  }

  return data?.status == 'Success' || data?.jobStatus == 'Success' ? (
    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
      All the records are successfully processed.
    </Typography>
  ) : (
    <Box sx={{ py: 2, px: 4 }}>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: 'fit-content',
          maxWidth: 500,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 600, width: 160 }}>
          Import Id:
        </Typography>
        <Typography variant="body2">{data.importId}</Typography>
      </Box>
      <Divider sx={{ borderColor: 'grey.300', pt: 1, mb: 1 }} />
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: 'fit-content',
          maxWidth: 500,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 600, width: 160 }}>
          Status:
        </Typography>
        <Typography variant="body2">{data.status}</Typography>
      </Box>
      <Divider sx={{ borderColor: 'grey.300', pt: 1, mb: 1 }} />
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: 'fit-content',
          maxWidth: 500,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 600, width: 160 }}>
          Total Errors:
        </Typography>
        <Typography variant="body2">{errorData.totalErrors}</Typography>
      </Box>
      <Divider sx={{ borderColor: 'grey.300', pt: 1, mb: 1 }} />
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: 'fit-content',
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 600, width: 160 }}>
          Error Details:
        </Typography>
      </Box>
      <Box>
        <TableContainer component={Paper} style={{ width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={headerStyle}>Item No</TableCell>
                <TableCell style={headerStyle}>Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ImportModalDetails;
