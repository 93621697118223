import { Box, Typography } from '@mui/material';

const updateManagementFeeInfo: string =
  ' This function will add or update the Management Fee GL corresponding to each tenant in Argus Enterprise with the amounts found in Yardi. The Management Fee GL has account number that matches the Lease ID of the corresponding tenant. Only existing tenants will have their GL accounts updated.';
const specUnitCleanupInfo: string =
  ' This function will zero the area for units that have a Suite Name that begin with "SPEC" and an available date prior to the date within the "SpeculativeUnitCleanupPrior" system configuration. It will also mark the suite name as "DELETE" to be able to quickly identify suites that have been adjusted.';
const deleteIncrementalHistoryInfo: string =
  ' This function will delete the history of incremental updates for the selected properties. If all properties are selected the history will be deleted for all properties. Once the incremental history is deleted for a property the next integration will include all data for that property for the chosen data points and, once completed, the incremental history will be updated. This should be done any time the integration scenario is changed as the incremental history does not take the AE scenario into account.';

const InfoSection = () => {
  return (
    <>
      <Box
        sx={{
          padding: 1,
          fontSize: '5px',
        }}
      >
        <Typography
          variant="caption"
          style={{
            fontWeight: 'bold',
          }}
        >
          Update Management Fees:
        </Typography>
        <Typography variant="caption">{updateManagementFeeInfo}</Typography>
        <div
          style={{
            marginTop: '8px',
          }}
        >
          <Typography
            variant="caption"
            style={{
              fontWeight: 'bold',
            }}
          >
            Spec Unit Cleanup:
          </Typography>
          <Typography variant="caption">{specUnitCleanupInfo}</Typography>
        </div>
        <div
          style={{
            marginTop: '8px',
          }}
        >
          <Typography
            variant="caption"
            style={{
              fontWeight: 'bold',
            }}
          >
            Delete Incremental History:
          </Typography>
          <Typography variant="caption">
            {deleteIncrementalHistoryInfo}
          </Typography>
        </div>
      </Box>
    </>
  );
};

export default InfoSection;
