import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { fetchData } from '../lib/fetchData';

const CountData = ({ selectDate }) => {
  const [count, setCounts] = useState<
    | {
        recordSent: number;
        recordSuccess: number;
        recordFailed: number;
      }
    | undefined
  >();

  useEffect(() => {
    fetchData(`gethourlyjobrecordcount/${selectDate}`, setCounts);
  }, [selectDate]);

  if (!count) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: ['column', 'column', 'row'],
        justifyContent: 'space-around',
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <Typography variant="h3">{count.recordSent}</Typography>
        <Typography variant="h5">Record</Typography>
        <Typography
          variant="h5"
          sx={{
            borderBottom: '3px solid',
            borderColor: 'orange.300',
            fontWeight: 600,
            mt: 0.2,
          }}
        >
          Sent
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="h3">{count.recordSuccess}</Typography>
        <Typography variant="h5">Record</Typography>
        <Typography
          variant="h5"
          sx={{
            borderBottom: '3px solid',
            borderColor: 'teal.400',
            fontWeight: 600,
            mt: 0.2,
          }}
        >
          Succeeded
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="h3">{count.recordFailed}</Typography>
        <Typography variant="h5">Record</Typography>
        <Typography
          variant="h5"
          sx={{
            borderBottom: '3px solid',
            borderColor: 'red.400',
            fontWeight: 600,
            mt: 0.2,
          }}
        >
          Failed
        </Typography>
      </Box>
    </Box>
  );
};

export default CountData;
