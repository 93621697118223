import { Stack } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import theme from './config/theme/theme';
import Layout from './layout/Layout';
import {
  AuthWrapper,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  login,
} from './lib/auth/auth';

const Login = () => {
  login();
  return <Stack></Stack>;
};

const App = () => {
  return (
    <React.Suspense fallback={<div>loading...</div>}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <AuthWrapper>
                <AuthenticatedTemplate>
                  <Layout />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                  <Login />
                </UnauthenticatedTemplate>
              </AuthWrapper>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </React.Suspense>
  );
};
export default App;
