import { Button, Typography } from '@mui/material';
import { useState } from 'react';

import ConfirmDialog from '../../hooks/ConfirmationPopUp';
import { fetchData, postData } from '../../lib/fetchData';

const UpdateManagementFee = ({
  state,
  propModel,
  setAlert,
  setOpenBackDrop,
}) => {
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const onExecute = () => {
    setOpenBackDrop(true);
    postData(`execute/managementfee`, propModel)
      .then((response) => {
        setOpenBackDrop(false);
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong.');
      })
      .then((response) => {
        if (response.responseStatus === 'SUCCESS') {
          setAlert({
            showAlert: true,
            message: 'Request successfully processed.',
            type: 'success',
          });
          return;
        }
        if (response.responseStatus === 'CONFLICT') {
          setAlert({
            showAlert: true,
            message: 'Process is already running, please try again later.',
            type: 'error',
          });
          return;
        }
        throw new Error('Something went wrong.');
      })
      .catch((error) => {
        setOpenBackDrop(false);
        setAlert({
          showAlert: true,
          message: 'Something went wrong. Please refresh page and try again.',
          type: 'error',
        });
        console.error('Error:', error);
      });
    setOpenConfirmation(false);
  };
  return (
    <>
      <Button
        style={{
          margin: '10px 0px 10px 0px',
          backgroundColor: '#c9f1f2',
          color: 'black',
        }}
        variant="contained"
        size="small"
        onClick={() => {
          setOpenConfirmation(true);
        }}
      >
        Update Management Fees
      </Button>
      <ConfirmDialog
        title="Confirmation?"
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        onConfirm={() => onExecute()}
      >
        Are you sure you want to update management fee?
        <div>
          <Typography
            variant="caption"
            style={{
              letterSpacing: 'normal',
            }}
          >
            Note: Please confirm your Scenario.
          </Typography>
        </div>
      </ConfirmDialog>
    </>
  );
};

export default UpdateManagementFee;
