import { Box } from '@mui/material';
import { useState, useEffect } from 'react';

import { MainTable } from '../components/MainTable';
import { fetchData } from '../lib/fetchData';

const columns = [
  { field: 'scheduleId', headerName: 'Schedule Id', flex: 1, minWidth: 100 },
  { field: 'executedOn', headerName: 'Executed On', flex: 1, minWidth: 190 },
  { field: 'completedOn', headerName: 'Completed On', flex: 1, minWidth: 190 },
  { field: 'remark', headerName: 'Remark', flex: 1, minWidth: 100 },
];

const HourlyJobSchedulerData = ({ selectDate }: { selectDate: string }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchData(`getjobschedulerhourlylog/${selectDate}`, setTableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDate]);

  return (
    <Box
      sx={{
        height: 240,
        '& .MuiDataGrid-row:nth-child(odd)': { backgroundColor: 'grey.100' },
      }}
    >
      <MainTable
        rows={tableData}
        columns={columns}
        hideFooter
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
        }
      />
    </Box>
  );
};

export default HourlyJobSchedulerData;
