import {
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { loginRequest, msalInstance, silentRequest } from '../auth/auth-config';
import { fetchData } from '../fetchData';

import { AuthProvider, useAuthContext } from './AuthContext';

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS &&
    !!event?.payload &&
    'account' in event.payload &&
    !!event.payload.account
  ) {
    const account = event.payload.account;

    msalInstance.setActiveAccount(account);
  }
});

function AuthWrapper({ children }: AuthProps) {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <Auth>{children}</Auth>
      </AuthProvider>
    </MsalProvider>
  );
}
const acquireSilentToken = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error('User not logged in');
  }
  try {
    const response = await msalInstance.acquireTokenSilent({
      account,
      ...silentRequest,
    });
    if (response.accessToken) {
      return response.accessToken;
    }
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenPopup(silentRequest);
    }
    console.error(error);
    return '';
  }
};
const acquireApiToken = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error('User not logged in');
  }
  try {
    const response = await msalInstance.acquireTokenSilent({
      account,
      ...loginRequest,
    });
    if (response.accessToken) {
      return response.accessToken;
    }
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenPopup(silentRequest);
    }
    console.error(error);
    return '';
  }
};

function login() {
  msalInstance.loginRedirect(loginRequest);
}

function logout() {
  msalInstance.logoutRedirect();
}
type AuthProps = { children: React.ReactNode };

const Auth = ({ children }: AuthProps) => {
  const { accounts, inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { dispatch } = useAuthContext();

  useEffect(() => {
    if (
      inProgress === InteractionStatus.None &&
      accounts.length > 0 &&
      isAuthenticated
    ) {
      const account = accounts[0];
      if (account) {
        fetchData(
          `userroles?userEmailId=${account.username.toLowerCase()}`,
          (data) => {
            dispatch({
              type: 'setUser',
              user: {
                name: account.name ?? '',
                username: account.username,
                roles: data,
              },
            });
          },
        );
      }
    }
  }, [inProgress, accounts, instance, isAuthenticated, dispatch]);
  return <>{children}</>;
};

/* Private Route */
type PrivateRouteProps = { children: JSX.Element };

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  if (!isAuthenticated) {
    navigate('/');
    return null;
  }
  return children;
};

export {
  acquireApiToken,
  acquireSilentToken,
  AuthWrapper,
  AuthenticatedTemplate,
  login,
  logout,
  PrivateRoute,
  UnauthenticatedTemplate,
};
