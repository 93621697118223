import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Avatar,
  Toolbar,
  Typography,
  CssBaseline,
  IconButton,
  Divider,
  Box,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import * as React from 'react';
import { ReactNode } from 'react';

import { MainMenu } from '../components/MainMenu';

import { DrawerMenuItemsType } from './MenuItems';

const LinkLogo = require('../assets/LinkLogo.svg');

export interface UserProfileMenuOptions {
  text?: string;
  onMenuClick?: () => void;
  iconComponent?: JSX.Element;
}

const drawerWidth = 180;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: theme.palette.grey[100],
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  backgroundColor: theme.palette.grey[50],
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function Header({
  children,
  drawerMenuItems,
  userProfileProps,
  userProfileMenuItems,
}: {
  children: ReactNode;
  drawerMenuItems: DrawerMenuItemsType[];
  userProfileProps: {
    firstName: string;
    profileURL: string;
  };
  userProfileMenuItems?: UserProfileMenuOptions[];
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openRightMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: 'grey.900', color: 'grey.50' }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 3,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon sx={{ color: 'secondary.light' }} />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              width: 'fill-available',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: ['none', 'block'] }}>
              <LinkLogo.ReactComponent />
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              color="secondary.light"
              sx={{ textTransform: 'uppercase', fontSize: [14, 20] }}
            >
              <Typography
                variant="h6"
                component="span"
                sx={{ fontWeight: 600, fontSize: [14, 20] }}
              >
                iHub -
              </Typography>{' '}
              Job Scheduler Report
            </Typography>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2, position: 'relative' }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar
                  src={
                    userProfileProps.profileURL
                      ? userProfileProps.profileURL
                      : null
                  }
                  sx={{
                    bgcolor: 'primary.main',
                    border: '2px solid',
                    borderColor: 'secondary.light',
                    backgroundColor: 'secondary.dark',
                  }}
                >
                  <PersonIcon />
                </Avatar>
                <SettingsIcon
                  sx={{
                    color: 'secondary.light',
                    position: 'absolute',
                    bottom: -2,
                    right: -2,
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <MainMenu
          drawerMenuItems={drawerMenuItems}
          openMenu={open}
          onMenuIconClick={handleDrawerOpen}
        />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: [0, 2] }}>
        <DrawerHeader />
        {children}
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openRightMenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {userProfileMenuItems.map((profileMenuItem) => (
          <MenuItem
            onClick={profileMenuItem.onMenuClick}
            key={profileMenuItem.text}
          >
            {profileMenuItem.iconComponent} {profileMenuItem.text}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
