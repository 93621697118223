import DownloadIcon from '@mui/icons-material/Download';
import {
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { GridActionsCellItem, GridRowId } from '@mui/x-data-grid';
import { useState, useEffect, useCallback } from 'react';

import { MainTable } from '../components/MainTable';
import ToastAlert from '../hooks/toast-alert';
import { fetchData, getData } from '../lib/fetchData';
import CustomModal from '../pages/status-modal';

const AnaplanJobLogDetails = () => {
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [openImportStatusModal, setOpenImportStatusModal] = useState(false);

  const [alert, setAlert] = useState<{
    showAlert: boolean;
    type: AlertColor;
    message: string;
  }>({
    showAlert: false,
    type: null,
    message: null,
  });

  const columns = [
    {
      field: 'functionName',
      headerName: 'Function Name',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'lastRunTime',
      headerName: 'Last Run Date Time',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'totalRecords',
      headerName: 'Total Records',
      flex: 1,
      maxWidth: 100,
    },
    {
      field: 'operationStatus',
      headerName: 'Operation Status',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        let rowValue = params.row;
        const handleDetailsClick = () => {
          setSelectedCell(rowValue);
          if (rowValue.importJson !== null) {
            setOpenModal(true);
          } else if (rowValue.remarks !== null) {
            setOpenImportStatusModal(true);
          }
        };
        return (
          <>
            {rowValue.importJson !== null || rowValue.remarks !== null ? (
              <a href="#" onClick={handleDetailsClick}>
                {params.value}
              </a>
            ) : (
              params.value
            )}
          </>
        );
      },
    },
    { field: 'requestedBy', headerName: 'RequestedBy', flex: 1, minWidth: 100 },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'ImportedFile',
      flex: 1,
      maxWidth: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const data = tableData.find((x) => x.id === id);
        if (data === null || data.fileName === null) {
          return [];
        }
        return [
          <GridActionsCellItem
            icon={<DownloadIcon />}
            label="Download"
            className="downloadimportfile"
            onClick={handleDowloadClick(data.fileName)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  const closeModal = () => {
    setOpenModal(false);
  };

  const handleRowClick = (cellData) => {
    setOpenModal(true);
    setSelectedCell(cellData);
  };

  useEffect(() => {
    fetchData('anaplanjoblog', setTableData);
  }, []);

  const handleRefresh = useCallback(() => {
    fetchData('anaplanjoblog', setTableData);
  }, []);

  const handleDowloadClick = (fileName: string) => () => {
    getData(`anaplanscheduler/downloadimportfile?fileName=${fileName}`)
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error('Something went wrong.');
      })
      .then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          return;
        }
        throw new Error('Something went wrong.');
      })
      .catch((error) => {
        setAlert({
          showAlert: true,
          message: 'Something went wrong. Please refresh page and try again.',
          type: 'error',
        });
        console.error('Error:', error);
      });
  };

  const handleAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({
      showAlert: false,
      message: '',
      type: 'success',
    });
  };

  return (
    <>
      <ToastAlert
        alertValues={alert}
        handleAlertClose={handleAlertClose}
      ></ToastAlert>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mb: 2,
          gap: 2,
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Logs/Previously Ingested modules</Typography>
        <Button variant="primary" onClick={handleRefresh}>
          Refresh
        </Button>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          height: '70vh',
          mb: 6,
          '& .MuiDataGrid-row:nth-child(odd)': { backgroundColor: 'grey.100' },
        }}
      >
        <MainTable
          rows={tableData}
          columns={columns}
          pageSize={100}
          hideFooter
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
          }
        />

        {selectedCell !== null && (
          <>
            <CustomModal
              open={openModal}
              onClose={closeModal}
              content={selectedCell}
              header="Import Status"
            />
            <Dialog open={openImportStatusModal} fullWidth={true} maxWidth={'md'}>
              <DialogTitle>Operation Status</DialogTitle>
              <DialogContent>{selectedCell.remarks}</DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOpenImportStatusModal(false);
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
    </>
  );
};

export default AnaplanJobLogDetails;
