import { RollerShades } from '@mui/icons-material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import { ReactNode } from 'react';

import { ROUTES } from '../config/routes';

export type DrawerMenuItemType = {
  id: string;
  text: string;
  onMenuClick?: () => void;
  iconComponent?: ReactNode;
};

export type DrawerMenuItemsType = DrawerMenuItemType & {
  submenu?: DrawerMenuItemType[];
};

export const menuItems = (
  userRole: string[],
  navigate: (param: string) => void,
) => {
  if (!userRole) {
    return;
  }

  const menuItems = {
    blackline: {
      id: 'blackline-menu',
      text: 'Blackline',
      iconComponent: <LineAxisIcon />,
      submenu: [
        {
          id: 'blackline',
          text: 'Integration',
          onMenuClick: () => navigate(ROUTES['blackline'].path),
          iconComponent: <LineAxisIcon />,
        },
        {
          id: 'blackline-scheduler',
          text: 'Scheduler',
          onMenuClick: () =>
            navigate(ROUTES['blackline'].submenu.scheduler.path),
          iconComponent: <LineAxisIcon />,
        },
      ],
    },
    anaplan: {
      id: 'anaplan-menu',
      text: 'Anaplan',
      iconComponent: <AssessmentIcon />,
      submenu: [
        {
          id: 'anaplan',
          text: 'Integration',
          onMenuClick: () => navigate(ROUTES['anaplan'].path),
          iconComponent: <LineAxisIcon />,
        },
        // {
        //   id: 'anaplan-scheduler',
        //   text: 'Scheduler',
        //   onMenuClick: () => navigate(ROUTES['anaplan'].submenu.scheduler.path),
        //   iconComponent: <LineAxisIcon />,
        // },
      ],
    },
    argus: {
      id: 'argus-menu',
      text: 'Argus',
      iconComponent: <AssessmentIcon />,
      submenu: [
        {
          id: 'argus',
          text: 'Integration',
          onMenuClick: () => navigate(ROUTES['argus'].path),
          iconComponent: <LineAxisIcon />,
        },
      ],
    },
  };

  if (userRole.some((x) => x.toLowerCase() === 'admin')) {
    return Object.values(menuItems);
  } else {
    var items = [];
    userRole.forEach((role) => {
      if (!menuItems[role.toLowerCase()]) {
        return;
      }
      items.push(menuItems[role.toLowerCase()]);
    });
    return items;
  }
};
