import {
  Box,
  CircularProgress,
  Container,
  LinearProgress,
} from '@mui/material';
import axios from 'axios';
// eslint-disable-next-line import/no-unresolved
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AppRouter from '../AppRotuer';
import useIsBigScreen from '../hooks/useIsBigScreen';
import { logout, acquireSilentToken } from '../lib/auth/auth';
import { graphConfig } from '../lib/auth/auth-config';
import { useAuthContext } from '../lib/auth/AuthContext';
import { fetchData } from '../lib/fetchData';

import HeaderLayout from './HeaderLayout';
import { menuItems } from './MenuItems';

const spDelvePageUrl = 'https://liprop-my.sharepoint.com/person.aspx';

const Layout = () => {
  const { state } = useAuthContext();
  const [currentUser, setCurrentUser] = useState(null);
  const [profilePicURL, setProfilePicURL] = useState(null);
  const [loading, setLoading] = useState(true);
  const isBigScreen = useIsBigScreen();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.user && state.user.name) {
      setCurrentUser(state.user);
      setLoading(false);
      // fetchProfilePic();
    } else {
      setLoading(false);
    }
  }, [state]);

  const fetchProfilePic = async () => {
    const token = await acquireSilentToken();
    const { data: photoValue } = await axios.request({
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
      url: graphConfig.graphPhotoEndpoint,
    });
    const blobUrl = window.URL.createObjectURL(photoValue);
    setProfilePicURL(blobUrl);
  };

  const onAccountProfileClick = () => {
    const delvePageUrl =
      process.env.REACT_APP_SHAREPOINT_DELVE_PAGE_URL || spDelvePageUrl;
    window.open(delvePageUrl, '_blank');
  };
  const hideout = () => {
    const hideList = ['leasedetails', 'addlease', 'editlease'];
    const checkpath = hideList.filter(
      (e) => window.location.href.indexOf(e) > -1,
    );
    if (checkpath.length > 0 && !isBigScreen) {
      return true;
    }
    return false;
  };

  const drawerMenuItems = menuItems(currentUser?.roles, navigate);

  return currentUser && drawerMenuItems?.length > 0 ? (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        !hideout() && (
          <HeaderLayout
            drawerMenuItems={drawerMenuItems}
            userProfileProps={{
              firstName: currentUser.name ? currentUser.name : '',
              profileURL: profilePicURL,
            }}
            userProfileMenuItems={[
              {
                text: 'Logout',
                onMenuClick: logout,
              },
              {
                text: 'Account Profile',
                onMenuClick: onAccountProfileClick,
              },
            ]}
          >
            <AppRouter userRole={currentUser?.roles} />
          </HeaderLayout>
        )
      )}
    </>
  ) : (
    <Container fixed sx={{ backgroundColor: 'grey.100' }}>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    </Container>
  );
};

export default Layout;
