import { Box } from '@mui/material';
import { useState, useEffect } from 'react';

import { MainTable } from '../components/MainTable';
import { fetchData } from '../lib/fetchData';

import ImportModal from './import-modal';

const HourlyJobLogDetailsData = ({ selectDate }) => {
  const [openImportModal, setOpenImportModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const columns = [
    {
      field: 'importId',
      headerName: 'Import Id',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const handleDetailsClick = () => {
          setOpenImportModal(true);
          setSelectedCell(params.row); // Assuming the 'details' field contains the cell data
        };
        return (
          <a href="#" onClick={handleDetailsClick}>
            {params.value}
          </a>
        );
      },
    },
    { field: 'type', headerName: 'Type', flex: 1, minWidth: 100 },
    { field: 'logStatus', headerName: 'Log Status', flex: 1, minWidth: 100 },
    {
      field: 'jobLastRunTime',
      headerName: 'Job Last RunTime',
      flex: 1,
      minWidth: 200,
    },
    { field: 'jobStatus', headerName: 'Job Status', flex: 1, minWidth: 100 },
    {
      field: 'logLastRunTime',
      headerName: 'Log Last RunTime',
      flex: 1,
      minWidth: 200,
    },
    { field: 'remarks', headerName: 'Remarks', flex: 1, minWidth: 1200 },
  ];

  const closeModal = () => {
    setOpenImportModal(false);
  };

  const [tableData, setTableData] = useState<
    | [
        {
          id: number;
          importId: number;
          jobLastRunTime: Date;
          jobStatus: string;
          logLastRunTime: string;
          logStatus: string;
          remarks: string;
          type: string;
        },
      ]
    | []
  >([]);

  useEffect(() => {
    fetchData(`gethourlyjobdetailslog/${selectDate}`, setTableData);
  }, [selectDate]);

  return (
    <Box
      sx={{
        height: 440,
        flexGrow: 1,
        '& .MuiDataGrid-row:nth-child(odd)': { backgroundColor: 'grey.100' },
      }}
    >
      <MainTable rows={tableData} columns={columns} hideFooter />
      {selectedCell !== null && (
        <ImportModal
          open={openImportModal}
          onClose={closeModal}
          content={selectedCell}
          header="Import Status"
        />
      )}
    </Box>
  );
};

export default HourlyJobLogDetailsData;
