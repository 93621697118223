import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material/';
import { ReactNode } from 'react';

const ConfirmDialog = ({
  title,
  children,
  open,
  handleClose,
  onConfirm,
}: {
  title: string;
  children: ReactNode;
  open: boolean;
  handleClose: () => void;
  onConfirm: () => void;
}) => (
  <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-dialog">
    <DialogTitle id="confirm-dialog">{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button onClick={handleClose} variant="text">
        No
      </Button>
      <Button
        variant="primary"
        onClick={() => {
          onConfirm();
          handleClose();
        }}
      >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
