import CloseIcon from '@mui/icons-material/Close';
import { Typography, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import React from 'react';

import ModalDetails from './modal-details';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const CustomModal = ({ open, onClose, content, header }) => {
  if (content.importJson == null) return;

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{ '& .MuiPaper-root': { width: '70%', maxWidth: 'none' } }}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {header}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <ModalDetails data={content}></ModalDetails>
      </DialogContent>
      <DialogActions>
        <Button variant="primary" autoFocus onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default CustomModal;
