import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Typography,
  Grid,
  FormControl,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useState, useEffect } from 'react';

import ConfirmDialog from '../../hooks/ConfirmationPopUp';
import { postData, fetchData } from '../../lib/fetchData';

const GeneralLedger = ({ authState, setAlert, setOpenBackDrop }) => {
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [propertyValue, setpropertyValue] = useState<string>('all');
  const [periodMonth, setPeriodMonthValue] = useState<string>(
    dayjs(new Date()).format('YYYY-MM-01'),
  );
  const [openPropertySelection, setOpenPropertySelection] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState<GridSelectionModel>([]);
  const [tempRowIds, setTempRowIds] = useState<GridSelectionModel>([]);
  const [properties, setProperties] = useState([]);

  const columns: GridColDef[] = [
    { field: 'propertyId', headerName: 'PID', width: 150 },
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'address', headerName: 'Address', width: 300 },
  ];

  const getProperties = () => {
    if (properties && properties.length > 0) {
      onOpenPropertySelection();
      return;
    }
    setOpenBackDrop(true);
    fetchData(`generalledger/properties`, (data) => {
      setOpenBackDrop(false);
      setProperties(data);
      setSelectedRowIds(Array.from({ length: data.length }, (_, i) => i));
      onOpenPropertySelection();
    });
  };

  const onSubmit = () => {
    var requestModel = {
      properties: null,
      periodDate: periodMonth,
      requestedBy: authState.user.name,
    };

    if (propertyValue === 'selected') {
      var p = selectedRowIds.map((i) => properties[i]?.propertyId);
      requestModel.properties = String(p);
    }
    setOpenBackDrop(true);
    postData(`executegeneralledger`, requestModel)
      .then((response) => {
        setOpenBackDrop(false);
        if (response.ok) {
          setAlert({
            showAlert: true,
            message: 'Request successfully processed.',
            type: 'success',
          });
          return;
        }
        throw new Error('Something went wrong.');
      })
      .catch((error) => {
        setOpenBackDrop(false);
        setAlert({
          showAlert: true,
          message: 'Something went wrong. Please refresh page and try again.',
          type: 'error',
        });
        console.error('Error:', error);
      });
    setOpenConfirmation(false);
  };

  const onOpenPropertySelection = () => {
    setTempRowIds(selectedRowIds);
    setOpenPropertySelection(true);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          textAlign: ['center', 'left'],
          border: 'solid',
          padding: 2,
          borderWidth: '1px',
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            textDecorationLine: 'underline',
            fontWeight: 'bold',
          }}
        >
          Adjust Previous General Ledger
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <Typography
              style={{
                fontWeight: 'bold',
              }}
              variant="subtitle1"
            >
              Select Properties:
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="blackline-general-properties-controlled-radio-buttons-group"
                name="blackline-general-properties-controlled-radio-buttons-group"
                value={propertyValue}
                onChange={(event, value) => {
                  setpropertyValue(value);
                  if (value && value === 'selected') {
                    getProperties();
                  } else {
                    setSelectedRowIds(
                      Array.from({ length: properties.length }, (_, i) => i),
                    );
                  }
                }}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio size="small" />}
                  label="All Properties"
                />
                <FormControlLabel
                  value="selected"
                  control={<Radio size="small" />}
                  label="Selected Properties"
                />
              </RadioGroup>
            </FormControl>
            <Typography
              variant="h6"
              textAlign={'center'}
              style={{ backgroundColor: 'lightgrey', fontSize: '15px' }}
            >
              {propertyValue === 'all' ? 'All' : selectedRowIds?.length}{' '}
              properties are selected
              {propertyValue === 'selected' && (
                <IconButton
                  color="success"
                  aria-label="edit properties"
                  onClick={() => onOpenPropertySelection()}
                  style={{ height: '10px' }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(periodMonth)}
                  onChange={(newValue) =>
                    setPeriodMonthValue(dayjs(newValue).format('YYYY-MM-01'))
                  }
                  label="Period Month"
                  views={['month', 'year']}
                  minDate={dayjs(
                    new Date().setFullYear(new Date().getFullYear() - 2),
                  )}
                  maxDate={dayjs(
                    new Date().setFullYear(new Date().getFullYear() + 2),
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Button
              style={{
                width: '250px',
                alignSelf: 'end',
              }}
              variant="contained"
              onClick={() => setOpenConfirmation(true)}
            >
              Execute General Ledger
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ConfirmDialog
        title="Confirmation?"
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        onConfirm={() => onSubmit()}
      >
        Are you sure you want to General Ledger?
      </ConfirmDialog>
      <Dialog open={openPropertySelection} fullWidth={true} maxWidth={'md'}>
        <DialogTitle>Select Properties</DialogTitle>
        <DialogContent>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              checkboxSelection
              selectionModel={selectedRowIds}
              onSelectionModelChange={(rowIds) => {
                setSelectedRowIds(rowIds);
              }}
              rows={properties.map((p, i) => ({
                id: i,
                name: p.name,
                propertyId: p.propertyId,
                address: p.address,
              }))}
              columns={columns}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpenPropertySelection(false)}
          >
            Done
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setSelectedRowIds(tempRowIds);
              setOpenPropertySelection(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GeneralLedger;
