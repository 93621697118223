import {
  Button,
  Divider,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Grid,
  AlertColor,
} from '@mui/material';
import { useCallback, useState,useEffect } from 'react';

import ConfirmDialog from '../hooks/ConfirmationPopUp';
import ToastAlert from '../hooks/toast-alert';
import { getData, fetchData } from '../lib/fetchData';

import ExportJobTrigger from './anaplan/export-job-trigger';
import YardiExportJobTrigger from './anaplan/yardi-export-job-trigger';

const AnaplanJobTriggerModule = ({ user }) => {
  const [functionSelected, setFunctionSelected] = useState<string>('All');
  const [functions, setfunctions] = useState([]);
  const [open, setOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<{
    showAlert: boolean;
    type: AlertColor;
    message: string;
  }>({
    showAlert: false,
    type: null,
    message: null,
  });

  useEffect(() => {
    fetchData('anaplanscheduler/functions?jobType=Import',setfunctions);
  }, []);

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleChange = useCallback((event: SelectChangeEvent) => {
    setFunctionSelected(event.target.value);
  }, []);

  const triggerFunctionModule = () => {
    let selectedFunction = functionSelected.toString();
    getData(
      `anaplanscheduler?functionName=${selectedFunction}&scheduledUser=${user.name}`,
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong.');
      })
      .then((response) => {
        if (response.responseStatus === 'OK') {
          setAlert({
            showAlert: true,
            message: 'Request successfully processed.',
            type: 'success',
          });
          return;
        }
        throw new Error('Something went wrong.');
      })
      .catch((error) => {
        setAlert({
          showAlert: true,
          message: 'Something went wrong. Please refresh page and try again.',
          type: 'error',
        });
        console.error('Error:', error);
      });
  };

  const handleAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({
      showAlert: false,
      message: '',
      type: 'success',
    });
  };

  return (
    <>
      <ToastAlert
        alertValues={alert}
        handleAlertClose={handleAlertClose}
      ></ToastAlert>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mt: 3,
          textAlign: ['center', 'left'],
        }}
      >
        <>
          <Typography variant="h5" textAlign={'center'}>
            Anaplan Report and Scheduler
            <Typography
              component="span"
              variant="h6"
              sx={{
                borderBottom: '3px solid',
                borderColor: 'red.800',
                ml: 1,
              }}
            >
              ({process.env.REACT_APP_ENV})
            </Typography>
          </Typography>

          <Divider sx={{ my: 1 }} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6">
                Select a model: (LDP to Anaplan)
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <FormControl sx={{ width: 300 }}>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={functionSelected}
                    label=""
                    defaultValue="24"
                    onChange={handleChange}
                    size="small"
                  >
                    {functions.map(model=>{
                        return <MenuItem value={model.functions}>{model.functions}</MenuItem>
                    })}
                    <MenuItem value={'All'}>All</MenuItem>
                  </Select>
                </FormControl>
                <Button variant="primary" onClick={handleClickOpen}>
                  Execute
                </Button>

                <ConfirmDialog
                  title="Confirmation?"
                  open={open}
                  handleClose={handleClose}
                  onConfirm={triggerFunctionModule}
                >
                  Are you sure you want to execute this model '
                  {functionSelected}
                  '?
                </ConfirmDialog>
              </Box>
            </Grid>
            {/* <Grid item xs={4}>
              <ExportJobTrigger
                handleAlert={(values) => {
                  setAlert(values);
                }}
                user={user}
              ></ExportJobTrigger>
            </Grid> */}
            {/* <Grid item xs={6}>
              <YardiExportJobTrigger
                handleAlert={(values) => {
                  setAlert(values);
                }}
                user={user}
              ></YardiExportJobTrigger>
            </Grid> */}
          </Grid>
        </>
      </Box>
    </>
  );
};

export default AnaplanJobTriggerModule;
