export const primary = {
  dark: '#4D7480',
  main: '#96B7C0',
  light: '#CADADF',
};

export const secondary = {
  dark: '#B2613A',
  main: '#e7c7b7',
  background: '#59301D',
};

export const tertiary = {
  main: '#DEC7AC',
};
