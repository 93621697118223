import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  CircularProgress,
  Backdrop,
  FormControlLabel,
  Typography,
} from '@mui/material';
import {
  MaterialReactTable,
  MRT_RowSelectionState,
  type MRT_ColumnDef,
} from 'material-react-table';
import { useState, useEffect, useMemo } from 'react';

const PropertySelection = ({
  openPropertySelection,
  handleDone,
  properties,
  selectedRowIds,
  includePropertyClassification,
  setincludePropertyClassification,
  onCancel,
}) => {
  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'hmy',
          header: 'HMY',
          size: 40,
          filterFn: 'contains',
        },
        {
          accessorKey: 'propertyId',
          header: 'Property ID',
          size: 40,
          filterFn: 'contains',
        },
        {
          accessorKey: 'propertyName',
          header: 'Property Name',
          size: 150,
          filterFn: 'contains',
        },
      ] as MRT_ColumnDef<(typeof properties)[0]>[],
    [],
  );
  const columnsWithClassification = useMemo(
    () =>
      [
        {
          accessorKey: 'hmy',
          header: 'HMY',
          size: 40,
          filterFn: 'contains',
        },
        {
          accessorKey: 'propertyId',
          header: 'Property ID',
          size: 40,
          filterFn: 'contains',
        },
        {
          accessorKey: 'propertyName',
          header: 'Property Name',
          size: 150,
          filterFn: 'contains',
        },
        {
          accessorKey: 'classificationName',
          header: 'Classification Name',
          size: 200,
          filterFn: 'contains',
        },
        {
          accessorKey: 'classificationType',
          header: 'Classification Type',
          size: 180,
          filterFn: 'contains',
        },
      ] as MRT_ColumnDef<(typeof properties)[0]>[],
    [],
  );
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [openBackdrop, setOpenBackDrop] = useState(false);

  useEffect(() => {
    setRowSelection(selectedRowIds.reduce((a, v) => ({ ...a, [v]: true }), {}));
  }, [selectedRowIds]);

  return (
    <>
      <Dialog open={openPropertySelection} fullWidth={true} maxWidth={'lg'}>
        <DialogTitle>
          Select Properties
          <span style={{ float: 'right' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={includePropertyClassification}
                  onChange={(event) => {
                    setincludePropertyClassification(
                      !includePropertyClassification,
                    );
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                  style={{ float: 'right' }}
                />
              }
              label="Include Classifications"
            />
          </span>
        </DialogTitle>
        <DialogContent>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div style={{ height: 400, width: '100%' }}>
            <MaterialReactTable
              columns={
                includePropertyClassification
                  ? columnsWithClassification
                  : columns
              }
              data={properties}
              enableStickyHeader
              enableStickyFooter
              muiTableContainerProps={{ sx: { maxHeight: '300px' } }}
              initialState={{ showColumnFilters: true, density: 'compact' }}
              enableGlobalFilter={false}
              enableRowSelection
              selectAllMode="all"
              onRowSelectionChange={setRowSelection}
              state={{ rowSelection }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              handleDone(rowSelection);
            }}
          >
            Done
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PropertySelection;
