import { DataGrid, DataGridProps } from '@mui/x-data-grid';

export const MainTable = ({ rows, columns, ...props }: DataGridProps) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      hideFooter
      rowHeight={50}
      {...props}
    />
  );
};
