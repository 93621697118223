import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';

import { DrawerMenuItemsType } from '../layout/MenuItems';

export const MainMenu = ({
  drawerMenuItems,
  openMenu,
  onMenuIconClick,
}: {
  drawerMenuItems: DrawerMenuItemsType[];
  openMenu: boolean;
  onMenuIconClick?: () => void;
}) => {
  const [open, setOpen] = useState({ [drawerMenuItems[0].id]: true });
  const [openSubmenu, setOpenSubmenu] = useState<string>();

  function handleClick(id) {
    if (Object.keys(open)[0] === id && openMenu) {
      setOpen(() => ({ [id]: !open[id] }));
      return;
    }
    setOpen(() => ({ [id]: true }));
  }
  return (
    <List>
      {drawerMenuItems.map((menuItem) => (
        <ListItem
          key={menuItem.text}
          disablePadding
          sx={{
            display: 'block',
            borderBottom: '1px solid',
            borderColor: 'grey.300',
          }}
          onClick={() => {
            onMenuIconClick();
            !!menuItem.submenu && menuItem.submenu.length > 0
              ? handleClick(menuItem.id)
              : menuItem.onMenuClick();
          }}
        >
          <ListItemButton
            sx={{
              minHeight: 32,
              justifyContent: openMenu ? 'initial' : 'center',
              padding: 1,
              color: 'primary.dark',
            }}
          >
            {menuItem.iconComponent}
            <ListItemText
              primary={menuItem.text}
              sx={{
                display: openMenu ? 'block' : 'none',
                ml: 1,
                color: 'grey.800',
              }}
            />
            {!!menuItem.submenu &&
              menuItem.submenu.length > 0 &&
              (open[menuItem.id] ? (
                <ExpandLess sx={{ display: openMenu ? 'block' : 'none' }} />
              ) : (
                <ExpandMore sx={{ display: openMenu ? 'block' : 'none' }} />
              ))}
          </ListItemButton>
          {menuItem.submenu && (
            <Collapse in={open[menuItem.id]} timeout="auto" unmountOnExit>
              {menuItem.submenu.map((submenu) => (
                <ListItemButton
                  sx={{
                    display: openMenu ? 'block' : 'none',
                    minHeight: 32,
                    color: 'primary.dark',
                    py: 0.4,
                    backgroundColor:
                      openSubmenu === submenu.id ||
                      location.pathname.slice(1) === submenu.id
                        ? 'primary.light'
                        : 'transparent',
                  }}
                  key={submenu.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    submenu.onMenuClick();
                    // handleClick(menuItem.id);
                    setOpenSubmenu(submenu.id);
                  }}
                >
                  <ListItemText
                    primary={submenu.text}
                    sx={{
                      ml: 3,
                      '& .-root': { fontSize: 15 },
                    }}
                  />
                </ListItemButton>
              ))}
            </Collapse>
          )}
        </ListItem>
      ))}
    </List>
  );
};
