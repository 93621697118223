import { Button } from '@mui/material';
import { useState } from 'react';

import ConfirmDialog from '../../hooks/ConfirmationPopUp';
import { fetchData, postData } from '../../lib/fetchData';

const UnitCleanUp = ({
  state,
  selectedIntegrationId,
  setAlert,
  setOpenBackDrop,
}) => {
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const onExecute = () => {
    var propModel = {
      integrationScenarioId: selectedIntegrationId,
      scheduledUser: state.user.name,
    };
    setOpenBackDrop(true);
    postData(`execute/unitcleanup`, propModel)
      .then((response) => {
        setOpenBackDrop(false);
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong.');
      })
      .then((response) => {
        if (response.responseStatus === 'SUCCESS') {
          setAlert({
            showAlert: true,
            message: 'Request successfully processed.',
            type: 'success',
          });
          return;
        }
        if (response.responseStatus === 'CONFLICT') {
          setAlert({
            showAlert: true,
            message: 'Process is already running, please try again later.',
            type: 'error',
          });
          return;
        }
        throw new Error('Something went wrong.');
      })
      .catch((error) => {
        setOpenBackDrop(false);
        setAlert({
          showAlert: true,
          message: 'Something went wrong. Please refresh page and try again.',
          type: 'error',
        });
        console.error('Error:', error);
      });
    setOpenConfirmation(false);
  };
  return (
    <>
      <Button
        style={{
          margin: '10px',
          backgroundColor: 'cornflowerblue',
          color: 'black',
        }}
        variant="contained"
        size="small"
        color="success"
        onClick={() => {
          setOpenConfirmation(true);
        }}
      >
        Spec Unit Cleanup
      </Button>
      <ConfirmDialog
        title="Confirmation?"
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        onConfirm={() => onExecute()}
      >
        Are you sure you want to execute spec unit cleanup?
      </ConfirmDialog>
    </>
  );
};

export default UnitCleanUp;
