import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { uniqueId } from 'lodash';

import JsonTable from '../dataservice/anaplan/JsonTable';

const ModalDetails = ({ data }) => {
  if (data.importJson == null) return;
  const jsonObject = JSON.parse(data.importJson);

  return (
    <Box>
      <Typography
        variant="subtitle1"
        sx={{
          backgroundColor: 'primary.main',
          fontWeight: 'bold',
          px: 2,
        }}
      >
        Task Status
      </Typography>

      <Box sx={{ py: 2, px: 4 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            width: 'fit-content',
            maxWidth: 500,
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 600, width: 160 }}>
            Status Message:
          </Typography>
          <Typography variant="subtitle2">
            {jsonObject?.status.message}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 'fit-content',
            maxWidth: 400,
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 600, width: 160 }}>
            Creation Time:
          </Typography>
          <Typography variant="subtitle2">
            {new Date(jsonObject?.task.creationTime).toUTCString()}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 'fit-content',
            maxWidth: 400,
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 600, width: 160 }}>
            Current Step:
          </Typography>
          <Typography variant="subtitle2">
            {jsonObject?.task.currentStep}
          </Typography>
        </Box>
      </Box>

      <Typography
        variant="subtitle1"
        sx={{
          backgroundColor: 'primary.main',
          fontWeight: 'bold',
          px: 2,
          mb: 2,
        }}
      >
        Task Details
      </Typography>
      <JsonTable jsonData={jsonObject?.task.result} />
    </Box>
  );
};

export default ModalDetails;
