import { Box, Button, FormControl, Grid, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useState, useEffect } from 'react';

import ConfirmDialog from '../../hooks/ConfirmationPopUp';
import { postData, fetchData } from '../../lib/fetchData';

const SchedulePeriod = ({ authState, setAlert, setOpenBackDrop }) => {
  const [schedulerModel, setSchedulerModel] = useState<{
    scheduledDate: string;
    periodDate: string;
    scheduledUser: string;
  }>({
    scheduledDate: dayjs(new Date()).format('YYYY-MM-DD'),
    periodDate: dayjs(new Date()).format('YYYY-MM-01'),
    scheduledUser: authState.user.name,
  });
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [currentPeriodMonth, setCurrentPeriodMonth] = useState<any>();

  useEffect(() => {
    fetchData(`periodmonth`, (data) => {
      setCurrentPeriodMonth(data);
    });
  }, []);

  const onSubmit = () => {
    setOpenBackDrop(true);
    postData(`blacklinescheduler`, schedulerModel)
      .then((response) => {
        setOpenBackDrop(false);
        if (response.ok) {
          setAlert({
            showAlert: true,
            message: 'Request successfully processed.',
            type: 'success',
          });
          return;
        }
        throw new Error('Something went wrong.');
      })
      .catch((error) => {
        setOpenBackDrop(false);
        setAlert({
          showAlert: true,
          message: 'Something went wrong. Please refresh page and try again.',
          type: 'error',
        });
        console.error('Error:', error);
      });
    setOpenConfirmation(false);
  };
  return (
    <>
      <Typography
        variant="h5"
        style={{
          backgroundColor: 'lightgreen',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        Current Period Month: {currentPeriodMonth}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          textAlign: ['center', 'left'],
          border: 'solid',
          padding: 2,
          borderWidth: '1px',
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            textDecorationLine: 'underline',
            marginBottom: '10px',
            fontWeight: 'bold',
          }}
        >
          Setup a Roll over Period (GL and SL)
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(schedulerModel.scheduledDate)}
                  onChange={(newValue) =>
                    setSchedulerModel((prevState) => ({
                      ...prevState,
                      scheduledDate: dayjs(newValue).format('YYYY-MM-DD'),
                    }))
                  }
                  label="Scheduled Date"
                  minDate={dayjs(
                    new Date().setFullYear(new Date().getFullYear() - 2),
                  )}
                  maxDate={dayjs(
                    new Date().setFullYear(new Date().getFullYear() + 2),
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(schedulerModel.periodDate)}
                  onChange={(newValue) =>
                    setSchedulerModel((prevState) => ({
                      ...prevState,
                      periodDate: dayjs(newValue).format('YYYY-MM-01'),
                    }))
                  }
                  label="Period Month"
                  views={['month', 'year']}
                  minDate={dayjs(
                    new Date().setFullYear(new Date().getFullYear() - 2),
                  )}
                  maxDate={dayjs(
                    new Date().setFullYear(new Date().getFullYear() + 2),
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Button
              style={{
                width: '250px',
                alignSelf: 'end',
              }}
              variant="contained"
              onClick={() => setOpenConfirmation(true)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ConfirmDialog
        title="Confirmation?"
        open={openConfirmation}
        handleClose={() => setOpenConfirmation(false)}
        onConfirm={() => onSubmit()}
      >
        Are you sure you want to Submit Scheduled Date:
        {schedulerModel.scheduledDate} and Period Date:
        {schedulerModel.periodDate}?
      </ConfirmDialog>
    </>
  );
};

export default SchedulePeriod;
