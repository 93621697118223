import { Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';

import { MainTable } from '../../components/MainTable';
import { fetchData } from '../../lib/fetchData';
import ImportModal from '../import-modal';

const SubLedgerLogs = ({}) => {
  const [openImportModal, setOpenImportModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const columns = [
    {
      field: 'importId',
      headerName: 'Import Id',
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        const handleDetailsClick = () => {
          setOpenImportModal(true);
          setSelectedCell(params.row); // Assuming the 'details' field contains the cell data
        };
        return params.value === 0 ? (
          '-NA-'
        ) : (
          <a href="#" onClick={handleDetailsClick}>
            {params.value}
          </a>
        );
      },
    },
    {
      field: 'lastRunTime',
      headerName: 'LastRun Time',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'functionName',
      headerName: 'Function Name',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'schedulerType',
      headerName: 'Scheduler Type',
      flex: 1,
      minWidth: 100,
    },
    { field: 'status', headerName: 'Status', flex: 1, minWidth: 100 },
    { field: 'remark', headerName: 'Remark', flex: 1, minWidth: 100 },
    { field: 'requestedBy', headerName: 'RequestedBy', flex: 1, minWidth: 100 },
  ];

  const closeModal = () => {
    setOpenImportModal(false);
  };

  const [tableData, setTableData] = useState<
    | [
        {
          id: number;
          importId: number;
          lastRunTime: Date;
          functionName: string;
          schedulerType: string;
          status: string;
          remark: string;
          requestedBy: string;
        },
      ]
    | []
  >([]);

  useEffect(() => {
    fetchData(`subledger/logs`, (data) => {
      setTableData(data);
    });
  }, []);
  return (
    <Box
      sx={{
        height: 240,
        ml: 3,
        '& .MuiDataGrid-row:nth-child(odd)': { backgroundColor: 'grey.100' },
      }}
    >
      <Typography variant="h5">Subledger Logs</Typography>
      <MainTable
        rows={tableData}
        columns={columns}
        hideFooter
        rowHeight={50}
        sx={{ color: 'red' }}
      />
      {selectedCell !== null && (
        <ImportModal
          open={openImportModal}
          onClose={closeModal}
          content={selectedCell}
          header="Import Status"
        />
      )}
    </Box>
  );
};

export default SubLedgerLogs;
