import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../config/routes';

const Home = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   navigate(`/${Object.values(ROUTES)[0]?.path}`);
  // }, [navigate]);

  return null;
};

export default Home;
