import { colors } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import { tertiary, primary, secondary } from './colors';
import { getThemedComponents } from './components';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    secondary: true;
    primary: true;
    tertiary: true;
    info: true;
  }
}

export const getDesignTokens = () => ({
  palette: {
    ...colors,
    primary,
    secondary,
    tertiary,
    text: {
      primary: colors.grey[900],
      secondary: colors.grey[600],
    },
  },
});

const theme = createTheme(getDesignTokens());
export default deepmerge(theme, getThemedComponents(theme));
