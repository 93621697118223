import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

const ModuleSelection = ({ getSelectedModules, systemConfig }) => {
  const [modules, setModules] = useState<{
    includePropertyHeader: boolean;
    includeContractualTenants: boolean;
    includeGLData: boolean;
    includeRecoveries: boolean;
  }>({
    includePropertyHeader: false,
    includeContractualTenants: false,
    includeGLData: false,
    includeRecoveries: false,
  });
  useEffect(() => {
    getSelectedModules(modules);
  }, [getSelectedModules, modules]);

  useEffect(() => {
    if (systemConfig == null) {
      return;
    }
    setModules({
      includePropertyHeader: systemConfig.includePropertyHeader,
      includeContractualTenants: systemConfig.includeContractualLeases,
      includeGLData:
        systemConfig.includeActuals ||
        systemConfig.includeBudgets ||
        systemConfig.includeForecast,
      includeRecoveries: systemConfig.includeRecoveries,
    });
  }, [systemConfig]);

  const getTenantsLabel = () => {
    return (
      <>
        Tenants{' '}
        <Typography variant="caption">
          (contractual and speculative leases)
        </Typography>
      </>
    );
  };
  return (
    <>
      <Typography
        variant="subtitle1"
        style={{ textDecorationLine: 'underline', fontWeight: 'bold' }}
      >
        Select Modules:
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={modules.includePropertyHeader}
              onChange={(event) => {
                setModules((prevState) => ({
                  ...prevState,
                  includePropertyHeader: event.target.checked,
                }));
              }}
            />
          }
          label="Property Header"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={modules.includeContractualTenants}
              onChange={(event) => {
                setModules((prevState) => ({
                  ...prevState,
                  includeContractualTenants: event.target.checked,
                }));
              }}
            />
          }
          label={getTenantsLabel()}
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={modules.includeGLData}
              onChange={(event) => {
                setModules((prevState) => ({
                  ...prevState,
                  includeGLData: event.target.checked,
                }));
              }}
            />
          }
          label="GL Data"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={modules.includeRecoveries}
              onChange={(event) => {
                setModules((prevState) => ({
                  ...prevState,
                  includeRecoveries: event.target.checked,
                }));
              }}
            />
          }
          label="Recoveries"
        />
      </FormGroup>
    </>
  );
};

export default ModuleSelection;
