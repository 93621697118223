import { Container, Divider, Grid } from '@mui/material';

import AnaplanJobTriggerModule from '../dataservice/AnaplanJobTriggerModule';
import AnaplanJobLogDetails from '../dataservice/AnaplanLogDetailsData';
import { useAuthContext } from '../lib/auth/AuthContext';

const AnaplanJobDetails = () => {
  const { state } = useAuthContext();
  return (
    <Container maxWidth="xl">
      <AnaplanJobTriggerModule user={state.user} />

      <Divider sx={{ my: 3 }} />
      <AnaplanJobLogDetails />
    </Container>
  );
};

export default AnaplanJobDetails;
