import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColumnHeaderParams, GridToolbar } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';

import { MainTable } from '../../components/MainTable';
import { fetchData } from '../../lib/fetchData';

const ArgusLogs = () => {
  const columns = [
    { field: 'id', headerName: 'Id', flex: 1, maxWidth: 50 },
    {
      field: 'instanceId',
      headerName: 'Instance Id',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const handleDetailsClick = () => {
          setOpenBackDrop(true);
          setOpenInstanceLogDialog(true);
          fetchData(`argusinstancelogs?instanceId=${params.value}`, (data) => {
            setOpenBackDrop(false);
            setInstanceTableData(data);
          });
        };
        return (
          <a href="#" onClick={handleDetailsClick}>
            {params.value}
          </a>
        );
      },
    },
    {
      field: 'moduleName',
      headerName: 'Module Name',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'startDateTimestamp',
      flex: 1,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => (
        <>
          {'Start Date'}
          <Tooltip title="DateTime is in UTC">
            <InfoOutlinedIcon />
          </Tooltip>
        </>
      ),
    },
    {
      field: 'endDateTimestamp',
      flex: 1,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => (
        <>
          {'End Date'}
          <Tooltip title="DateTime is in UTC">
            <InfoOutlinedIcon />
          </Tooltip>
        </>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      maxWidth: 100,
    },
    { field: 'requestedBy', headerName: 'RequestedBy', flex: 1, maxWidth: 100 },
    {
      field: 'remarks',
      headerName: 'Remarks',
      flex: 1,
      maxWidth: 150,
    },
  ];

  const instanceColumns = [
    { field: 'id', headerName: 'Id', flex: 1, maxWidth: 75 },
    {
      field: 'instanceId',
      headerName: 'Instance Id',
      flex: 1,
      maxWidth: 100,
    },
    {
      field: 'batchNumber',
      headerName: 'Batch',
      flex: 1,
      maxWidth: 50,
    },
    {
      field: 'moduleName',
      headerName: 'Module Name',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'startDateStamp',
      flex: 1,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => (
        <>
          {'Start Date'}
          <Tooltip title="DateTime is in UTC">
            <InfoOutlinedIcon />
          </Tooltip>
        </>
      ),
    },
    {
      field: 'endDateStamp',
      flex: 1,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => (
        <>
          {'End Date'}
          <Tooltip title="DateTime is in UTC">
            <InfoOutlinedIcon />
          </Tooltip>
        </>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      maxWidth: 100,
    },
    { field: 'noOfRecords', headerName: 'NoOfRecords', flex: 1, minWidth: 100 },
    { field: 'requestedBy', headerName: 'RequestedBy', flex: 1, maxWidth: 100 },
    { field: 'notes', headerName: 'Notes', flex: 1, minWidth: 300 },
  ];

  const [tableData, setTableData] = useState<
    | [
        {
          id: string;
          instanceId: string;
          moduleName: string;
          startDateTimestamp: Date;
          requestedBy: string;
        },
      ]
    | []
  >([]);

  const [instanceTableData, setInstanceTableData] = useState<
    | [
        {
          id: number;
          instanceId: string;
          batchNumber: number;
          moduleName: string;
          startDateStamp: Date;
          endDateStamp: Date;
          status: string;
          noOfRecords: string;
          requestedBy: string;
          notes: string;
        },
      ]
    | []
  >([]);

  const [openInstanceLogDialog, setOpenInstanceLogDialog] = useState(false);
  const [openBackdrop, setOpenBackDrop] = useState(false);

  useEffect(() => {
    fetchData(`arguslogs`, (data) => {
      data.map((row, index) => (row['id'] = index + 1));
      setTableData(data);
    });
  }, []);
  return (
    <Box
      sx={{
        height: 400,
        '& .MuiDataGrid-row:nth-child(odd)': { backgroundColor: 'grey.100' },
        marginTop: '25px',
      }}
    >
      <Typography variant="h5">Argus Logs</Typography>
      <MainTable
        rows={tableData}
        columns={columns}
        hideFooter
        rowHeight={50}
        sx={{ color: 'red' }}
      />
      <Dialog open={openInstanceLogDialog} fullWidth={true} maxWidth={'xl'}>
        <DialogTitle>Instance Logs</DialogTitle>
        <DialogContent>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <div style={{ height: 750, width: '100%' }}>
            <MainTable
              rows={instanceTableData}
              columns={instanceColumns}
              hideFooter
              rowHeight={50}
              sx={{ color: 'red' }}
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: { printOptions: { disableToolbarButton: true } },
              }}
              // initialState={{
              //   columns: {
              //     columnVisibilityModel: {
              //       // Hide columns status and traderName, the other columns will remain visible
              //       instanceId: false,
              //     },
              //   },
              // }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpenInstanceLogDialog(false);
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ArgusLogs;
