import { Container, Grid, Typography } from '@mui/material';
import React from 'react';

import CountData from '../dataservice/CountData';
import FullLoadJobDetailData from '../dataservice/FullLoadJobDetailData';
import HourlyJobLogDetailsData from '../dataservice/HourlyJobLogDetailsData';
import HourlyJobSchedulerData from '../dataservice/HourlyJobSchedulerData';
import PeriodDate from '../dataservice/PeriodDate';
import PieChart from '../dataservice/PieChart';

const BlackLineJobDetails = () => {
  const [date, setDate] = React.useState('24');

  const dateChange = (date: string) => {
    setDate(date);
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={6} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={12}>
          <PeriodDate onhandleChange={dateChange} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h5">Job Scheduler (Voyager)</Typography>
          <HourlyJobSchedulerData selectDate={date} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h5">
            Full Load Job (Full Load + Full Load Log)
          </Typography>
          <FullLoadJobDetailData />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography variant="h5">
            Houly Job (Hourly Job Log + Hourly Job)
          </Typography>
          <HourlyJobLogDetailsData selectDate={date} />
        </Grid>

        {/* <Grid xs={12} sm={12}>
          <Typography variant="h5">
            Blackline Full Load Job (Full Load)
          </Typography>
          <FullLoadJobDetailData />
        </Grid> */}

        <Grid item xs={12} sm={12}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={3}>
              <PieChart selectDate={date} />
            </Grid>
            <Grid item xs={12} sm={9}>
              <CountData selectDate={date} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* </>
      ) : (
        <p style={{ textAlign: "center", marginTop: "6rem", fontSize: "2rem" }}>
          Please login to see data
        </p>
      )} */}
    </Container>
  );
};

export default BlackLineJobDetails;
