import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material';
import React from 'react';

interface NestedResult {
  objectId: string;
  successful: boolean;
  failureDumpAvailable: boolean;
  details?: { localMessageText: string }[];
}

const JsonTable = ({ jsonData }) => {
  const groupedRows: { [key: string]: NestedResult[] } = {};
  const tableRowsRoot = [];

  const cellStyle = {
    border: '1px solid black',
    padding: '8px',
    verticalAlign: 'top',
  };

  const headerStyle = {
    fontWeight: 'bold',
    border: '1px solid black',
    padding: '8px',
  };

  tableRowsRoot.push(
    <TableRow key={jsonData.objectId}>
      <TableCell style={cellStyle}>{jsonData.objectId}</TableCell>
      <TableCell style={cellStyle}>
        {jsonData.successful.toString().toUpperCase()}
      </TableCell>
      <TableCell style={cellStyle}>
        {jsonData.failureDumpAvailable.toString().toUpperCase()}
      </TableCell>
      <TableCell style={cellStyle}>NA</TableCell>
    </TableRow>,
  );

  jsonData.nestedResults.forEach((result) => {
    const { objectId, successful, failureDumpAvailable } = result;

    if ('details' in result) {
      const detailsText = result.details
        .map((detail) =>
          'localMessageText' in detail ? detail.localMessageText : '',
        )
        .join('\n');

      if (!(objectId in groupedRows)) {
        groupedRows[objectId] = [];
      }

      groupedRows[objectId].push({
        objectId,
        successful,
        failureDumpAvailable,
        details: [{ localMessageText: detailsText }],
      });
    } else {
      if (!(objectId in groupedRows)) {
        groupedRows[objectId] = [];
      }

      groupedRows[objectId].push({
        objectId,
        successful,
        failureDumpAvailable,
        details: [],
      });
    }
  });

  const tableRows = Object.values(groupedRows).map((group) => {
    const firstGroup = group[0];

    const detailsText = firstGroup.details.length
      ? firstGroup.details[0].localMessageText
      : 'NA';
    return (
      <TableRow key={firstGroup.objectId}>
        <TableCell style={cellStyle}>{firstGroup.objectId}</TableCell>
        <TableCell style={cellStyle}>
          {firstGroup.successful.toString().toUpperCase()}
        </TableCell>
        <TableCell style={cellStyle}>
          {firstGroup.failureDumpAvailable.toString().toUpperCase()}
        </TableCell>
        <TableCell style={cellStyle}>
          <div>
            {detailsText.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {index > 0 && <br />}
                {!line.includes('NA') ? (
                  <>
                    <span>{index + 1}.</span>
                    <strong>Field: </strong>
                  </>
                ) : (
                  ''
                )}
                <span
                  dangerouslySetInnerHTML={{
                    __html: line.replace(
                      /cells:/,
                      ', <strong>Row Count:</strong>',
                    ),
                  }}
                />
              </React.Fragment>
            ))}
          </div>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Box>
      <TableContainer component={Paper} style={{ width: '100%' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={headerStyle}>Object Id</TableCell>
              <TableCell style={headerStyle}>Successful</TableCell>
              <TableCell style={headerStyle}>Failure Dump Available</TableCell>
              <TableCell style={headerStyle}>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableRowsRoot}</TableBody>
          <TableBody>{tableRows}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default JsonTable;
