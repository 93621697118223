import { createContext, Dispatch, useContext, useReducer } from 'react';
type AuthState = { user?: User };
type Action = { type: 'setUser'; user: User };
export type User = { name: string; username: string; roles: string[] };
const initialState: AuthState = { user: undefined };
const AuthContext = createContext<{
  state: AuthState;
  dispatch: Dispatch<Action>;
} | null>(null);
AuthContext.displayName = 'AuthContext';

const authReducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'setUser':
      return { ...state, user: action.user };
    default:
      return state;
  }
};

type Props = {
  children?: React.ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuthContext must be used withing a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuthContext };
