import {
  Typography,
  FormControl,
  FormControlLabel,
  Box,
  Checkbox,
  TextField,
  Autocomplete,
  Grid,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import { fetchData } from '../../lib/fetchData';

function generateYears() {
  let years = [];
  for (var i = 2000; i <= 2099; i++) {
    years.push(i);
  }
  return years;
}

function generateNumberofYears() {
  let noOfYears = [];
  for (var i = 1; i <= 30; i++) {
    noOfYears.push(i);
  }
  return noOfYears;
}

const GLAccounts = ({ getGLModel, systemConfig }) => {
  const [books, setBooks] = useState([]);

  const [gLModel, setGLModel] = useState<{
    includeActual: boolean;
    actualsFromDate: string;
    actualsToDate: string;
    actualsBookArray: [];
    includeBudget: boolean;
    budgetFromDate: string;
    budgetToDate: string;
    budgetBookArray: [];
    includeForecast: boolean;
    forecastYear: number;
    forecastDuplicateYears: number;
    forecastBookArray: [];
  }>({
    includeActual: false,
    actualsFromDate: dayjs(new Date()).format('YYYY-MM-DD'),
    actualsToDate: dayjs(new Date()).format('YYYY-MM-DD'),
    actualsBookArray: [],
    includeBudget: false,
    budgetFromDate: dayjs(new Date()).format('YYYY-MM-DD'),
    budgetToDate: dayjs(new Date()).format('YYYY-MM-DD'),
    budgetBookArray: [],
    includeForecast: false,
    forecastYear: null,
    forecastDuplicateYears: null,
    forecastBookArray: [],
  });

  useEffect(() => {
    getGLModel(gLModel);
  }, [gLModel, getGLModel]);

  useEffect(() => {
    if (systemConfig == null) {
      return;
    }
    setGLModel({
      includeActual: systemConfig.includeActuals,
      actualsFromDate: dayjs(systemConfig.actualsBeginDate).format(
        'YYYY-MM-DD',
      ),
      actualsToDate: dayjs(systemConfig.actualsEndDate).format('YYYY-MM-DD'),
      actualsBookArray: systemConfig.actualsBookList,
      includeBudget: systemConfig.includeBudgets,
      budgetFromDate: dayjs(systemConfig.budgetBeginDate).format('YYYY-MM-DD'),
      budgetToDate: dayjs(systemConfig.budgetEndDate).format('YYYY-MM-DD'),
      budgetBookArray: systemConfig.budgetBookList,
      includeForecast: systemConfig.includeForecast,
      forecastYear: systemConfig.forecastYear,
      forecastDuplicateYears: systemConfig.forecastDuplicateYears,
      forecastBookArray: systemConfig.forecastBookList,
    });
  }, [systemConfig]);

  useEffect(() => {
    if (books.length < 1) {
      fetchData(`books`, (data) => {
        setBooks(data);
      });
    }
  }, [books]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mb: 2,
          textAlign: ['center', 'left'],
          border: 'solid',
          padding: 2,
          borderWidth: '1px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={gLModel.includeActual}
                    onChange={(event) => {
                      setGLModel((prevState) => ({
                        ...prevState,
                        includeActual: event.target.checked,
                      }));
                    }}
                  />
                }
                label="Include Actuals:"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(gLModel.actualsFromDate)}
                  onChange={(newValue) =>
                    setGLModel((prevState) => ({
                      ...prevState,
                      actualsFromDate: dayjs(newValue).format('YYYY-MM-DD'),
                    }))
                  }
                  label="From"
                  disabled={!gLModel.includeActual && !gLModel.includeForecast}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(gLModel.actualsToDate)}
                  onChange={(newValue) =>
                    setGLModel((prevState) => ({
                      ...prevState,
                      actualsToDate: dayjs(newValue).format('YYYY-MM-DD'),
                    }))
                  }
                  label="To"
                  disabled={!gLModel.includeActual && !gLModel.includeForecast}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <Autocomplete
                multiple
                limitTags={1}
                id="autocomplete-actuals-books"
                disableCloseOnSelect
                value={gLModel.actualsBookArray}
                onChange={(event, newValue: []) => {
                  setGLModel((prevState) => ({
                    ...prevState,
                    actualsBookArray: newValue,
                  }));
                }}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option}
                  </li>
                )}
                options={books}
                sx={{ width: 350 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Actuals Book" />
                )}
                disabled={!gLModel.includeActual}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={gLModel.includeBudget}
                    onChange={(event) => {
                      setGLModel((prevState) => ({
                        ...prevState,
                        includeBudget: event.target.checked,
                      }));
                    }}
                  />
                }
                label="Include Budget:"
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(gLModel.budgetFromDate)}
                  onChange={(newValue) =>
                    setGLModel((prevState) => ({
                      ...prevState,
                      budgetFromDate: dayjs(newValue).format('YYYY-MM-DD'),
                    }))
                  }
                  label="From"
                  disabled={!gLModel.includeBudget}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={dayjs(gLModel.budgetToDate)}
                  onChange={(newValue) =>
                    setGLModel((prevState) => ({
                      ...prevState,
                      budgetToDate: dayjs(newValue).format('YYYY-MM-DD'),
                    }))
                  }
                  label="To"
                  disabled={!gLModel.includeBudget}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <Autocomplete
                multiple
                limitTags={1}
                id="autocomplete-budget-books"
                disableCloseOnSelect
                value={gLModel.budgetBookArray}
                onChange={(event, newValue: []) => {
                  setGLModel((prevState) => ({
                    ...prevState,
                    budgetBookArray: newValue,
                  }));
                }}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option}
                  </li>
                )}
                options={books}
                sx={{ width: 350 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Budget Book" />
                )}
                disabled={!gLModel.includeBudget}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={gLModel.includeForecast}
                    onChange={(event) => {
                      setGLModel((prevState) => ({
                        ...prevState,
                        includeForecast: event.target.checked,
                      }));
                    }}
                  />
                }
                label="Include Forecast:"
              />
              <Typography variant="caption">
                Note: Actuals Date Range will be used for Recovery Adjustment
                Book.
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <Autocomplete
                value={gLModel.forecastYear}
                onChange={(event: any, newValue: number | null) => {
                  setGLModel((prevState) => ({
                    ...prevState,
                    forecastYear: newValue,
                  }));
                }}
                id="forecast-year"
                getOptionLabel={(option) =>
                  typeof option == 'number' ? String(option) : option
                }
                options={generateYears()}
                sx={{ width: 150 }}
                renderInput={(params) => <TextField {...params} label="Year" />}
                disabled={!gLModel.includeForecast}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <Autocomplete
                value={gLModel.forecastDuplicateYears}
                onChange={(event: any, newValue: number | null) => {
                  setGLModel((prevState) => ({
                    ...prevState,
                    forecastDuplicateYears: newValue,
                  }));
                }}
                id="forecast-duplicate-years"
                getOptionLabel={(option) =>
                  typeof option == 'number' ? String(option) : option
                }
                options={generateNumberofYears()}
                sx={{ width: 150 }}
                renderInput={(params) => (
                  <TextField {...params} label="Duplicate Years" />
                )}
                disabled={!gLModel.includeForecast}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <Autocomplete
                multiple
                limitTags={1}
                id="autocomplete-forecast-books"
                disableCloseOnSelect
                value={gLModel.forecastBookArray}
                onChange={(event, newValue: []) => {
                  setGLModel((prevState) => ({
                    ...prevState,
                    forecastBookArray: newValue,
                  }));
                }}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option}
                  </li>
                )}
                options={books}
                sx={{ width: 350 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Forecast Book" />
                )}
                disabled={!gLModel.includeForecast}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default GLAccounts;
