import { Configuration, PublicClientApplication } from '@azure/msal-browser';
const ua = window.navigator.userAgent;
const isIE = ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0;
const isEdge = ua.indexOf('Edge/') > 0;
const isFirefox = ua.indexOf('Firefox') > 0;

export const msalConfig: Configuration = {
  auth: {
    clientId: `${process.env.REACT_APP_APPLICATION_ID}`,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AUTH_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URL,
    postLogoutRedirectUri: process.env.REACT_APP_AUTH_REDIRECT_URL,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: isIE || isEdge || isFirefox, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);
export const apiConfig = {
  resourceScopes: [`${process.env.REACT_APP_AUTH_SCOPE_URL}/.default`],
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const allScopes = [
  'email',
  'openid',
  'profile',
  'offline_access',
  'User.Read',
  'https://graph.microsoft.com/user.read',
];
export const loginRequest = {
  scopes: [...apiConfig.resourceScopes], //'Files.ReadWrite'...apiConfig.resourceScopes
};

export const silentRequest = {
  scopes: [...allScopes], //'Files.ReadWrite'...apiConfig.resourceScopes
};

export const COOKIE_TOKEN = 'token';
// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphPhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
};
