import type { Theme } from '@mui/material/styles';

export const getThemedComponents = (theme: Theme) => ({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableTouchRipple: true,
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            backgroundColor: theme.palette.primary.main,
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            backgroundColor: theme.palette.secondary.main,
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.grey[200],
        },
      },
    },
  },
});
