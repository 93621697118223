import { acquireApiToken } from './auth/auth';

export const fetchData = async (serviceName, callback) => {
  const token = await acquireApiToken();
  fetch(process.env.REACT_APP_API_BASE_URL + `/${serviceName}`, {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key':
        process.env.REACT_APP_API_APIM_SUBSCRIPTION_KEY || '',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + token,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Something went wrong.');
    })
    .then((data) => {
      data && callback(data);
    })
    .catch((error) => console.error('Error:', error));
};

export const getData = async (serviceName) => {
  const token = await acquireApiToken();
  return fetch(process.env.REACT_APP_API_BASE_URL + `/${serviceName}`, {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key':
        process.env.REACT_APP_API_APIM_SUBSCRIPTION_KEY || '',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + token,
    },
  });
};

export const postData = async (serviceName, requestBody) => {
  const token = await acquireApiToken();
  return fetch(process.env.REACT_APP_API_BASE_URL + `/${serviceName}`, {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key':
        process.env.REACT_APP_API_APIM_SUBSCRIPTION_KEY || '',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  });
};
