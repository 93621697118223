import { Box } from '@mui/material';
import { useState, useEffect } from 'react';

import { MainTable } from '../components/MainTable';
import { fetchData } from '../lib/fetchData';

import ImportModal from './import-modal';

const FullLoadJobDetailData = () => {
  const [openImportModal, setOpenImportModal] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const columns = [
    {
      field: 'importId',
      headerName: 'Import Id',
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        const handleDetailsClick = () => {
          setOpenImportModal(true);
          setSelectedCell(params.row); // Assuming the 'details' field contains the cell data
        };
        return (
          <a href="#" onClick={handleDetailsClick}>
            {params.value}
          </a>
        );
      },
    },
    {
      field: 'lastrunDateTime',
      headerName: 'Lastrun Date Time',
      flex: 1,
      minWidth: 200,
    },
    { field: 'startCount', headerName: 'Start Count', flex: 1, minWidth: 100 },
    { field: 'endCount', headerName: 'End Count', flex: 1, minWidth: 100 },
    { field: 'type', headerName: 'Type', flex: 1, minWidth: 100 },
    { field: 'status', headerName: 'Status', flex: 1, minWidth: 100 },
  ];
  const closeModal = () => {
    setOpenImportModal(false);
  };
  const [tableData, setTableData] = useState<
    | [
        {
          endCount: number;
          id: number;
          importId: number;
          lastrunDateTime: Date;
          startCount: number;
          status: string;
          type: string;
        },
      ]
    | []
  >([]);

  useEffect(() => {
    fetchData(`getfullloadjobdetailslog`, setTableData);
  }, []);

  return (
    <Box
      sx={{
        height: 240,
        '& .MuiDataGrid-row:nth-child(odd)': { backgroundColor: 'grey.100' },
      }}
    >
      <MainTable
        rows={tableData}
        columns={columns}
        hideFooter
        rowHeight={50}
        sx={{ color: 'red' }}
      />
      {selectedCell !== null && (
        <ImportModal
          open={openImportModal}
          onClose={closeModal}
          content={selectedCell}
          header="Import Status"
        />
      )}
    </Box>
  );
};

export default FullLoadJobDetailData;
