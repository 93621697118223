import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  Typography,
  Grid,
} from '@mui/material';
import Moment from 'moment';
import { useEffect, useState } from 'react';

import { fetchData } from '../lib/fetchData';

const PeriodDate = ({ onhandleChange }) => {
  const [date, setDate] = useState([]);
  const [dateSelected, setDateSelected] = useState('24');

  const handleChange = (event: SelectChangeEvent) => {
    setDateSelected(event.target.value);
    onhandleChange(event.target.value);
  };

  useEffect(() => {
    fetchData(`gethourlyjobcurrentperioddate`, (data) =>
      setDate(data.currentPeriodDate),
    );
  }, []);

  return (
    <Grid container sx={{ alignItems: 'center', p: 0 }}>
      <Grid item xs={12} sm={10} sx={{ textAlign: ['center', 'left'], gap: 2 }}>
        <Typography
          variant="h4"
          component="span"
          sx={{
            borderBottom: '3px solid',
            borderColor: 'red.800',
            lineHeight: 1.5,
          }}
        >
          Current Period Date:
        </Typography>
        <Typography
          variant="h4"
          component="span"
          sx={{ pl: 2, display: ['block', 'inline-block'], lineHeight: 1.5 }}
        >
          {date && Moment(date).format('LL')}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Filter</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={dateSelected}
            label="Filter"
            defaultValue="24"
            onChange={handleChange}
          >
            <MenuItem value={'24'}>Last 24 Hours</MenuItem>
            <MenuItem value={'6'}>Last 6 Hours</MenuItem>
            <MenuItem value={'1'}>Last 1 Hours</MenuItem>
            <MenuItem value={'48'}>Last 48 Hours</MenuItem>
            <MenuItem value={'72'}>Last 3 Days</MenuItem>
            <MenuItem value={'168'}>Last 7 Days</MenuItem>
            <MenuItem value={'all'}>All</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default PeriodDate;
