import {
  AlertColor,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { useState } from 'react';

import AdjustGeneralLedgerLogs from '../dataservice/blackline/adjust-general-ledger-logs';
import BlacklineDownloadFile from '../dataservice/blackline/download-files';
import GeneralLedger from '../dataservice/blackline/general-ledger';
import SchedulePeriod from '../dataservice/blackline/schedule-period';
import SubLedger from '../dataservice/blackline/sub-ledger';
import SubLedgerLogs from '../dataservice/blackline/sub-ledger-logs';
import ToastAlert from '../hooks/toast-alert';
import { useAuthContext } from '../lib/auth/AuthContext';

const BlackLineScheduler = ({ }) => {
  const { state } = useAuthContext();
  const [alert, setAlert] = useState<{
    showAlert: boolean;
    type: AlertColor;
    message: string;
  }>({
    showAlert: false,
    type: null,
    message: null,
  });
  const [openBackdrop, setOpenBackDrop] = useState(false);

  const handleAlertClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({
      showAlert: false,
      message: '',
      type: 'success',
    });
  };

  return (
    <Container maxWidth="xl">
      <ToastAlert
        alertValues={alert}
        handleAlertClose={handleAlertClose}
      ></ToastAlert>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mt: 3,
          textAlign: ['center', 'left'],
        }}
      >
        <SchedulePeriod
          authState={state}
          setAlert={setAlert}
          setOpenBackDrop={setOpenBackDrop}
        ></SchedulePeriod>
        <BlacklineDownloadFile setAlert={setAlert}>
        </BlacklineDownloadFile>
        <GeneralLedger
          authState={state}
          setAlert={setAlert}
          setOpenBackDrop={setOpenBackDrop}
        ></GeneralLedger>
        <SubLedger
          authState={state}
          setAlert={setAlert}
          setOpenBackDrop={setOpenBackDrop}
        ></SubLedger>
      </Box>
      <Box
        sx={{
          mt: 10,
          ml: 6,
        }}
      >
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <AdjustGeneralLedgerLogs />
          </Grid>

          <Grid item xs={6}>
            <SubLedgerLogs />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default BlackLineScheduler;
