import { colors } from '@mui/material';
import { useEffect, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import { fetchData } from '../lib/fetchData';

const PieChartData = ({ selectDate }) => {
  const [count, setCounts] = useState<
    | {
        recordSent: number;
        recordSuccess: number;
        recordFailed: number;
      }
    | undefined
  >();

  useEffect(() => {
    fetchData(`gethourlyjobrecordcount/${selectDate}`, setCounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectDate]);

  if (!count) {
    return null;
  }

  return (
    <PieChart
      data={[
        {
          title: 'Success',
          value: count.recordSuccess,
          color: colors.teal[300],
        },
        { title: 'Sent', value: count.recordSent, color: colors.yellow[800] },
        { title: 'Failed', value: count.recordFailed, color: colors.red[400] },
      ]}
      label={({ dataEntry }) => (dataEntry.value !== 0 ? dataEntry.value : '')}
    />
  );
};

export default PieChartData;
