import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Typography,
  Grid,
  FormControl,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { useState } from 'react';

import { getData } from '../../lib/fetchData';

const BlacklineDownloadFile = (setAlert) => {
  const [propertyValue, setpropertyValue] = useState<string>('DependentValidation');
  const handleDowloadClick = () => {
    getData(`downloadfile/${propertyValue}`)
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error('Something went wrong.');
      })
      .then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${propertyValue}.txt`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          return;
        }
        throw new Error('Something went wrong.');
      })
      .catch((error) => {
        setAlert({
          showAlert: true,
          message: 'Something went wrong. Please refresh page and try again.',
          type: 'error',
        });
        console.error('Error:', error);
      });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          textAlign: ['center', 'left'],
          border: 'solid',
          padding: 2,
          borderWidth: '1px',
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            textDecorationLine: 'underline',
            fontWeight: 'bold',
          }}
        >
          Download Files
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={8}>
            <Typography
              style={{
                fontWeight: 'bold',
              }}
              variant="subtitle1"
            >
              Select File:
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="blackline-general-properties-controlled-radio-buttons-group"
                name="blackline-general-properties-controlled-radio-buttons-group"
                value={propertyValue}
                onChange={(event, value) => {
                  setpropertyValue(value);
                }}
              >
                <FormControlLabel
                  value="DependentValidation"
                  control={<Radio size="small" />}
                  label="Dependent Validation"
                />
                <FormControlLabel
                  value="Validation"
                  control={<Radio size="small" />}
                  label="Validation"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Button
              style={{
                width: '250px',
                alignSelf: 'end',
              }}
              variant="contained"
              onClick={() => handleDowloadClick()}
            >
              Download File
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BlacklineDownloadFile;
